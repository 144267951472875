<template>
  <div>
    <Header />
    <section id="report">
      <div class="container ipad_p_l_5">
        <b-row>
          <b-col lg="2"></b-col>
          <b-col lg="8">
            <div class="create-report ecom_txt">
              <h2 class="create-title">Create report</h2>
            </div>
            <b-row>
              <b-col md="7">
                <div class="template">
                  <div class="choose-temp">
                    <p class="own">Choose template or create your own</p>
                  </div>
                </div>
              </b-col>
              <b-col md="5" class="btn_right p_r_10">
                <div class="template">
                  <div class="btn-custom">
                    <a href="#"
                      ><router-link to="custom_report"
                        >Create a Custom Report</router-link
                      ></a
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="container" id="message">
              <div class="row">
                <div class="group-report">
                  <div class="classis-studup">
                    <div
                      class="col-12 col-md-6 col-lg-6 col-xl-6 classis-report"
                    >
                      <div class="message-icon">
                        <i class="fas fa-comments"></i>
                      </div>
                      <div class="message-heading">
                        <h4>Class is Standup Report</h4>
                        <a href="#">Class 3-question standup</a>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-6 sales">
                      <div class="message-icon">
                        <i class="fas fa-comments"></i>
                      </div>
                      <div class="message-heading">
                        <h4>Sales Standup Report</h4>
                        <a href="#">Run standup with sales people</a>
                      </div>
                    </div>
                  </div>
                  <div class="classis-studup">
                    <div
                      class="col-12 col-md-6 col-lg-6 col-xl-6 classis-report"
                    >
                      <div class="message-icon">
                        <i class="fas fa-comments"></i>
                      </div>
                      <div class="message-heading">
                        <h4>Marketing Standup</h4>
                        <a href="#">Run standup with Marketing people</a>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-6 sales">
                      <div class="message-icon">
                        <i class="fas fa-comments"></i>
                      </div>
                      <div class="message-heading">
                        <h4>Dev Standup Report</h4>
                        <a href="#">Advanced developer standup</a>
                      </div>
                    </div>
                  </div>
                  <div class="classis-studup" @click="routeTo">
                    <div
                      class="col-12 col-md-6 col-lg-6 col-xl-6 classis-report"
                    >
                      <div class="message-icon">
                        <i class="fas fa-comments"></i>
                      </div>
                      <div class="message-heading">
                        <h4>First Milestone</h4>
                        <a href="#">Shortened custom report</a>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 col-xl-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col lg="2"></b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  name: 'Reports',
  components: {
    Header
  },
  methods: {
    routeTo () {
      this.$router.push(
        {
          path: '/create-report'
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
#report {
  margin-top: 50px;
}
/* #report .create-title{
    margin-bottom: 45px;
} */
#report .template {
  display: flex;
  margin-bottom: 10px;
}

#report .choose-temp {
  margin-right: 15px;
}
#report .choose-temp p {
  font-weight: 500;
  color: #3e3d3d;
  font-size: 20px;
}
#report .btn-custom {
  text-align: right;
  flex: auto;
  /* margin-right: 28px; */
}
#report .btn-custom span i {
  color: rgb(255, 0, 0);
}
#report .btn-custom a {
  color: #fff;
  background-color: #3196e6;
  padding: 10px 15px;
  border-radius: 5px;
}
#report .btn-custom a:hover {
  color: #fff;
  background-color: #218838;
  text-decoration: none;
}
#report .group-report .classis-studup {
  display: flex;
  margin-bottom: 20px;
}
#report .group-report .classis-report:hover {
  background-color: #fefe67;
  cursor: pointer;
}
#report .group-report .classis-report {
  border: 1px solid #ccc;
  margin-right: 15px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 4px;
}

#report .group-report .sales {
  border: 1px solid #ccc;
  display: flex;
  border-radius: 4px;
}
#report .group-report .sales:hover {
  background-color: #fefe67;
  cursor: pointer;
}
#report .group-report .message-icon {
  margin-right: 15px;
  margin-left: 10px;
}
#report .group-report .message-icon i {
  font-size: 45px;
  margin-top: 20px;
}
#report .group-report .message-heading {
  margin: 20px 25px 13px 0px;
}
#report .group-report .message-heading a {
  color: rgb(163, 163, 163);
  text-decoration: underline;
  font-size: 14px;
}
#report .group-report .message-heading h4 {
  font-size: 22px;
  color: #3e3d3d;
}
/* box-shadow class */

#report .group-report .classis-report {
  box-shadow: 0px 1px 0px #ccc;
}
#report .group-report .sales {
  box-shadow: 0px 1px 0px #ccc;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
