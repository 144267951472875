<template>
  <div class="Settings">
    <Header />
    <div class="sub-header">
      <div style="width:200px" class="icon">
        <i style="font-size:30px; margin-right:10px" class="fas fa-angle-left"></i>
        <span>Back</span>
      </div>
      <div>
        <p style="color:#77828b;">11TS ecom</p>
        <h3>Settings</h3>
      </div>
    </div>
    <b-container>
      <div class="heading">
        <b-row>
          <b-col md="6">
            <h5 style="margin:10px 0">User settings</h5>
          </b-col>
          <b-col md="6">
            <div class="flex">
              <h5 style="width:250px">Set user status to</h5>
              <b-form-select v-model="status">
                <option value="active">active</option>
                <option value="inactive">inactive</option>
              </b-form-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="card">
        <h5 style="font-weight:bold">Language and translations</h5>
        <b-row>
          <b-col md="6">
            <div class="flex">
              <p style="width:250px">User language</p>
              <b-form-select v-model="status">
                <option value="active">active</option>
                <option value="inactive">inactive</option>
              </b-form-select>
            </div>
          </b-col>
          <b-col md="6">
            <div class="flex">
              <b-form-checkbox v-model="status" name="check-button" switch size="lg">
                <p>Auto-translate text responses in user language</p>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="card">
        <h5 style="font-weight:bold">Timezone</h5>
        <b-row>
          <b-col md="6">
            <div class="flex">
              <p style="width:250px">Timezone</p>
              <b-form-select v-model="status">
                <option value="active">active</option>
                <option value="inactive">inactive</option>
              </b-form-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <div style="border:1px solid #7777; width:100%; margin-top:20px;"></div>
      <div class="heading">
        <b-row>
          <b-col md="6">
            <h5>Admin settings</h5>
          </b-col>
        </b-row>
      </div>

      <div class="card">
        <h5 style="font-weight:bold">Language and translations</h5>
        <b-row>
          <b-col md="8">
            <div class="flex">
              <p style="width:250px">Admin language</p>
              <b-form-select v-model="status">
                <option value="active">active</option>
                <option value="inactive">inactive</option>
              </b-form-select>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: "Settings",
  components: {
    Header
  },
  data() {
    return {
      status: ""
    };
  }
};
</script>

<style>
.card {
  background: #f5f5f5;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: 20px;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.4);
  box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.4);
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading {
  margin: 20px 0;
}
.sub-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #d0e8ff;
}
.icon {
  padding: 30px;
  color: #067aff;
  align-items: center;
  font-size: 20px;
  display: flex;
}
</style>