<template>
  <div class="createReport">
    <Header />

    <div class="report-header">
      <b-container>
        <b-row>
          <b-col md="12">
            <h6 v-if="activeTeam">{{ activeTeam.name }}</h6>
          </b-col>
          <b-col md="4">
            <h5>Create custom report</h5>
          </b-col>
          <b-col md="4">
            <div class="steps">
              <div class="step" v-bind:class="{ active: step == 1 }">
                1
                <span>. Define Questions</span>
              </div>
              <div class="divider"></div>
              <div class="step" v-bind:class="{ active: step == 2 }">
                2
                <span>. Scheduling</span>
              </div>
              <div class="divider"></div>
              <div class="step" v-bind:class="{ active: step == 3 }">
                3
                <span>. Results Delivery</span>
              </div>
            </div>
            <div class="flex">
              <select :value="languageTarget">
                <option
                  :value="lang.name"
                  :key="lang.name"
                  v-for="lang in langsAvailable"
                >{{lang.title}}</option>
              </select>
            </div>
          </b-col>
          <b-col md="4"></b-col>
        </b-row>
      </b-container>
    </div>

    <b-container v-if="step == 1">
      <h4 style="margin-top:10px;" class="required">Report name</h4>
      <div class="question-box">
        <b-row class="questions">
          <b-col cols="12">
            <b-form-input v-model="reportName" placeholder="Type report name"></b-form-input>
          </b-col>
        </b-row>
      </div>

      <div class="space-50"></div>
      <h4 class="required">Welcome message</h4>
      <div class="question-box">
        <b-row class="questions">
          <b-col cols="5">
            <b-form-textarea v-model="welcomeMessage" placeholder="Enter welcome message" rows="3"></b-form-textarea>
          </b-col>
          <b-col cols="2 text-center">
            <img class="translation_flag" src="../assets/img/german.png" />
          </b-col>
          <b-col cols="5">
            <b-form-textarea
              v-model="welcomeMessageTranslated"
              placeholder="Willkommensnachricht eingeben"
              rows="3"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </div>

      <div class="space-50"></div>
      <h4 class="required">Questions</h4>

      <div class="question-box" v-for="(question, index) in questions" :key="index">
        <div class="side-button" v-if="questions.length > 1">
          <a @click="moveQuestion('up', index)" class="arrow-btn">
            <i class="fas fa-chevron-up"></i>
          </a>
          <a @click="deleteQuestion(index)" class="cross-btn">
            <i class="far fa-times-circle"></i>
          </a>
          <a @click="moveQuestion('down', index)" class="arrow-btn">
            <i class="fas fa-chevron-down"></i>
          </a>
        </div>

        <b-row class="questions">
          <b-col cols="5">
            <b-form-textarea v-model="question.question" placeholder="Enter question" rows="3"></b-form-textarea>
          </b-col>
          <b-col cols="2 text-center">
            <img class="translation_flag" src="../assets/img/german.png" />
          </b-col>
          <b-col cols="5">
            <b-form-textarea
              v-model="question.questionTranslated"
              placeholder="Frage eingeben "
              rows="3"
            ></b-form-textarea>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="4">
            <b-form-select
              @change="checkOptionType(index)"
              v-model="question.type"
              :options="questionTypes"
            ></b-form-select>
          </b-col>
          <b-col cols="2" class="conditions-btn">
            <b-button @click="toggleCondition(index)">
              Conditions
              <span
                v-bind:class="{ active: question.conditionCount > 0 }"
              >&nbsp;{{ question.conditionCount }}&nbsp;</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- <b-row v-if="question.type == 'task-response'">
          <b-col cols="4">
            <div>
              <p>Projects</p>
              <b-form-select
                v-on:change="getAssignee"
                v-model="question.jira.projects"
                :options="projects"
              ></b-form-select>
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <p>Assignee</p>
              <b-form-select
                v-model="question.jira.assignee"
                :options="assinee"
              ></b-form-select>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-checkbox
              v-model="question.jira.activeSprint"
              name="activeSprint"
              value="true"
              unchecked-value="false"
            >
              Show only tickets of active sprint
            </b-form-checkbox>
          </b-col>
          <b-col cols="12">
            <b-form-checkbox
              v-model="question.jira.suggestPlanned"
              name="suggestPlanned"
              value="true"
              unchecked-value="false"
            >
              Suggest planned tickets from the last working day
            </b-form-checkbox>
          </b-col>
          <b-col cols="12">
            <b-form-checkbox
              v-model="question.jira.individualTicketComment"
              name="individualTicketComment"
              value="true"
              unchecked-value="false"
            >
              Individual tickets can be commented on by the respondent
            </b-form-checkbox>
          </b-col>
          <b-col cols="12">
            <b-form-checkbox
              v-model="question.jira.syncComments"
              name="syncComments"
              value="true"
              unchecked-value="false"
            >
              Sync comments with Jira
            </b-form-checkbox>
          </b-col>

          <b-col cols="12">
            <b-form-checkbox
              v-model="question.jira.allowToWrite"
              name="allowToWrite"
              value="true"
              unchecked-value="false"
            >
              Allow respondent to write new tickets during Stand-up
            </b-form-checkbox>
          </b-col>
        </b-row>-->

        <b-row
          v-if="
            question.type == 'single-choice' ||
              question.type == 'multiple-choice'
          "
        >
          <b-col cols="12">
            <h6 class="options-title">Options</h6>
          </b-col>
          <b-col cols="5">
            <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
              <template #prepend>
                <b-input-group-text>{{ i + 1 }}</b-input-group-text>
              </template>
              <b-form-input v-model="option.value" placeholder="Survey option"></b-form-input>

              <template #append>
                <b-input-group-text @click="deleteOption(question, i)" class="danger">
                  <i class="far fa-trash-alt"></i>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-col>
          <b-col cols="2 text-center">
            <img class="translation_flag" src="../assets/img/german.png" />
          </b-col>
          <b-col cols="5">
            <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
              <template #prepend>
                <b-input-group-text>{{ i + 1 }}</b-input-group-text>
              </template>
              <b-form-input v-model="option.value_translated" :placeholder="i==0 ? 'Nein' : 'Ja'"></b-form-input>

              <template #append>
                <b-input-group-text @click="deleteOption(question, i)" class="danger">
                  <i class="far fa-trash-alt"></i>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-col>
          <b-col cols="12">
            <button class="add-btn" @click="addOption(question)">Add option</button>
          </b-col>
        </b-row>

        <!--......yes-no response .......... -->
        <b-row v-if="question.type == 'yes-no'">
          <b-col cols="12">
            <h6 class="options-title">Options</h6>
          </b-col>
          <b-col cols="5">
            <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
              <template #prepend>
                <b-input-group-text v-if="i == 0">{{ "Positive" }}</b-input-group-text>
                <b-input-group-text v-else>{{ "Negative" }}</b-input-group-text>
              </template>
              <b-form-input v-model="option.value" :placeholder="i == 0 ? 'Positive' : 'Negative'"></b-form-input>

              <template #append>
                <b-input-group-text @click="deleteOption(question, i)" class="danger">
                  <i class="far fa-trash-alt"></i>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-col>
          <b-col cols="2 text-center">
            <img class="translation_flag" src="../assets/img/german.png" />
          </b-col>
          <b-col cols="5">
            <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
              <template #prepend>
                <b-input-group-text v-if="i == 0">{{ "Positive" }}</b-input-group-text>
                <b-input-group-text v-else>{{ "Negative" }}</b-input-group-text>
              </template>
              <b-form-input v-model="option.value_translated" :placeholder="i == 0 ? 'Nein' : 'Ja'"></b-form-input>

              <template #append>
                <b-input-group-text @click="deleteOption(question, i)" class="danger">
                  <i class="far fa-trash-alt"></i>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-col>
          <div class="col-12 d-sm-flex align-items-center">
            <!-- checkbox -->
            <b-form-checkbox
              v-model="question.showIconInsteadOfText"
              class="col-12 col-sm-auto"
              :value="true"
            >Use icon instead of text</b-form-checkbox>
            <!-- OPTION SELECTOR YES OR NO  -->

            <b-col class="col-12 col-sm-5" cols="4">
              <b-form-select
                @change="showoptiontype(index)"
                v-if="question.showIconInsteadOfText"
                v-model="question.iconType"
                :options="yesnoTypes"
              ></b-form-select>
              <div class="h2 mb-0"></div>
            </b-col>
          </div>

          <b-col cols="12" v-if="!question.showIconInsteadOfText">
            <button class="add-btn" @click="addOption(question)">Add option</button>
          </b-col>
        </b-row>
      </div>

      <button class="add-btn" @click="addQuestion()">Add question</button>

      <div class="space-100"></div>
    </b-container>
    <b-container v-if="step == 2">
      <div class="space-50"></div>
      <b-row>
        <b-col md="4">
          <h5 class="required">Start Date</h5>
          <b-form-datepicker v-model="schedule.start_date" :min="new Date()" locale="en"></b-form-datepicker>
        </b-col>
        <b-col md="4"></b-col>
        <!-- <b-col md="4"></b-col> -->
        <b-col md="4">
          <h5>Timezone</h5>
          <b-form-select v-model="schedule.timezone" @change="generateTimezone(schedule.timezone)">
            <option
              v-for="(option,index) in tzStrings"
              :key="index"
              :value="option.value"
            >{{option.label}}</option>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4"></b-col>
        <b-col md="4"></b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col md="12">
          <h5 class="required">Respondents</h5>
          <multiselect
            :multiple="true"
            class="userresponse1"
            v-model="schedule.respondents"
            placeholder="Type name(s)"
            label="name"
            track-by="name"
            :options="slack_users"
            :option-height="30"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <img :src="props.option.image" />
              {{ props.option.name }}
            </template>
            <template slot="option" slot-scope="props">
              <img :src="props.option.image" />
              {{ props.option.name }}
            </template>
          </multiselect>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col md="12">
          <h5>Schedule</h5>
        </b-col>

        <b-col md="4">
          <p class="required">Sending Time</p>
          <b-form-timepicker v-model="schedule.send_time" locale="en"></b-form-timepicker>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <p class="required">Sending Days</p>
        </b-col>
      </b-row>

      <div class="border-1">
        <b-row class="mt-2">
          <b-col md="8">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="schedule.send_days"
                :aria-describedby="ariaDescribedby"
                name="flavour-2"
              >
                <b-form-checkbox
                  v-for="(day, i) in days_list"
                  :key="i"
                  class="mr-5"
                  :value="day"
                >{{days[i]}}</b-form-checkbox>
                <!-- <b-form-checkbox class="mr-5" value="tue">Tue</b-form-checkbox>
                <b-form-checkbox class="mr-5" value="wed">Wed</b-form-checkbox>
                <b-form-checkbox class="mr-5" value="thu">Thu</b-form-checkbox>
                <b-form-checkbox class="mr-5" value="fri">Fri</b-form-checkbox>
                <b-form-checkbox class="mr-5" value="sat">Sat</b-form-checkbox>
                <b-form-checkbox class="mr-5" value="sun">Sun</b-form-checkbox>-->
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-select v-model="schedule.repeat_type" :options="repeatType"></b-form-select>
          </b-col>
        </b-row>
      </div>

      <b-row class="mt-5">
        <b-col md="12"></b-col>
      </b-row>
      <p>
        <b>Standup options</b>
      </p>
      <br />
      <p class="required">Waiting time</p>
      <b-row>
        <b-col md="4">
          <b-form-select
            label="Waiting time"
            v-model="schedule.waiting_time"
            :options="meetingLength"
          ></b-form-select>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="12">
          <b-form-checkbox
            id="checkbox-1"
            v-model="schedule.end_time_reminder"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >End time reminder</b-form-checkbox>
        </b-col>
        <b-col md="4" class="mt-2">
          <b-form-select
            :disabled="!schedule.end_time_reminder"
            v-model="schedule.end_time"
            :options="meetingLength"
          ></b-form-select>
        </b-col>
        <b-col md="8" class="mt-2">
          <b-form-input
            :disabled="!schedule.end_time_reminder"
            v-model="schedule.reminder_message"
            placeholder="Reminder message"
          ></b-form-input>
        </b-col>
      </b-row>

      <div class="space-50"></div>
      <div class="space-100"></div>
    </b-container>

    <b-container v-if="step == 3">
      <div class="space-50"></div>
      <b-row>
        <b-col md="12">
          <h5>Deliver results to</h5>
          <b-form-tags
            separator=" ,"
            placeholder="Type email address(es)"
            input-id="tags-basic"
            v-model="delivery.emails"
          ></b-form-tags>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="12">
          <h5>People via Slack message</h5>
          <multiselect
            :multiple="true"
            v-model="delivery.users"
            placeholder="Type name(s)"
            label="name"
            track-by="name"
            :options="slack_users"
            :option-height="30"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <img :src="props.option.image" />
              {{ props.option.name }}
            </template>
            <template slot="option" slot-scope="props">
              <img :src="props.option.image" />
              {{ props.option.name }}
            </template>
          </multiselect>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="12">
          <h5>Channels</h5>
          <multiselect
            :multiple="true"
            v-model="delivery.channels"
            placeholder="Type channel name(s)"
            label="name"
            track-by="name"
            :options="slack_channels"
            :option-height="30"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <b-icon icon="hash" aria-hidden="true"></b-icon>
              {{ props.option.name }}
            </template>
            <template slot="option" slot-scope="props">
              <b-icon icon="hash" aria-hidden="true"></b-icon>
              {{ props.option.name }}
            </template>
          </multiselect>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="12">
          <b-form-checkbox
            id="checkbox-1"
            v-model="threaded"
            name="checkbox-1"
            value="true"
            class="stepuser"
            unchecked-value="false"
          >Start a thread with report results</b-form-checkbox>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="12">
          <b-form-checkbox
            id="checkbox-2"
            v-model="async_delivery"
            name="checkbox-2"
            value="true"
            class="stepuser"
            unchecked-value="false"
          >Asynchronous results delivery</b-form-checkbox>
        </b-col>
      </b-row>

      <div class="space-50"></div>
      <div class="space-100"></div>
    </b-container>

    <div class="report-footer">
      <b-button @click="prevStep()" variant="link" :disabled="step == 1">
        <i class="fas fa-chevron-left"></i> Back
      </b-button>
      <b-button style="width:120px;" @click="sendTest()" variant="outline-success">Test run</b-button>
      <b-button @click="submitReport()" variant="success">Save</b-button>
      <b-button @click="nextStep()" :disabled="step == 3" variant="link">
        Next
        <i class="fas fa-chevron-right"></i>
      </b-button>
    </div>

    <b-modal
      v-model="modalShow"
      scrollable
      cancel-variant="outline-success"
      ok-variant="success"
      ok-title="Save"
      size="lg"
      title="Conditions"
    >
      <h6 class="mt-3">Specify people</h6>

      <multiselect
        :multiple="true"
        v-model="delivery.users"
        placeholder="Type name(s)"
        label="name"
        track-by="name"
        :options="slack_users"
        :option-height="30"
        :show-labels="false"
      >
        <template slot="singleLabel" slot-scope="props">
          <img :src="props.option.image" />
          {{ props.option.name }}
        </template>
        <template slot="option" slot-scope="props">
          <img :src="props.option.image" />
          {{ props.option.name }}
        </template>
      </multiselect>

      <h6 class="mt-3">Question Days</h6>

      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="schedule.send_days"
          :aria-describedby="ariaDescribedby"
          name="flavour-2"
        >
          <b-form-checkbox class="mr-5" value="mon">Mon</b-form-checkbox>
          <b-form-checkbox class="mr-5" value="tue">Tue</b-form-checkbox>
          <b-form-checkbox class="mr-5" value="wed">Wed</b-form-checkbox>
          <b-form-checkbox class="mr-5" value="thu">Thu</b-form-checkbox>
          <b-form-checkbox class="mr-5" value="fri">Fri</b-form-checkbox>
          <b-form-checkbox class="mr-5" value="sat">Sat</b-form-checkbox>
          <b-form-checkbox class="mr-5" value="sun">Sun</b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>

      <h6 class="mt-3">Additional Question</h6>

      <b-form-checkbox
        id="checkbox-1"
        v-model="threaded"
        name="checkbox-1"
        value="true"
        unchecked-value="false"
      >Make survey anonymous</b-form-checkbox>

      <b-form-checkbox
        id="checkbox-1"
        v-model="threaded"
        name="checkbox-1"
        value="true"
        unchecked-value="false"
      >Don't attach the chart with the survey results to the report summary</b-form-checkbox>

      <h6 class="mt-3">Make survey smarter with conditions</h6>
      <b-button variant="outline-success" @click="addConditions()">Add Condition</b-button>

      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="Ask specific Question" active>
            <div class="question-box" v-for="(question, index) in questions" :key="index">
              <div class="side-button" v-if="questions.length > 1">
                <a @click="moveQuestion('up', index)" class="arrow-btn">
                  <i class="fas fa-chevron-up"></i>
                </a>
                <a @click="deleteQuestion(index)" class="cross-btn">
                  <i class="far fa-times-circle"></i>
                </a>
                <a @click="moveQuestion('down', index)" class="arrow-btn">
                  <i class="fas fa-chevron-down"></i>
                </a>
              </div>

              <b-row class="questions">
                <b-col cols="5">
                  <b-form-textarea
                    v-model="question.question"
                    placeholder="Enter Question"
                    rows="3"
                  ></b-form-textarea>
                </b-col>
                <b-col cols="2 text-center">
                  <img class="translation_flag" src="../assets/img/german.png" />
                </b-col>
                <b-col cols="5">
                  <b-form-textarea
                    v-model="question.questionTranslated"
                    placeholder="Enter Question"
                    rows="3"
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="4">
                  <b-form-select v-model="question.type" :options="questionTypes"></b-form-select>
                </b-col>
                <b-col cols="4" class="conditions-btn">
                  <b-button @click="toggleCondition(index)">
                    Conditions
                    <span
                      v-bind:class="{ active: question.conditionCount > 0 }"
                    >&nbsp;{{ question.conditionCount }}&nbsp;</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-if="
                  question.type == 'single-choice' ||
                    question.type == 'multiple-choice'
                "
              >
                <b-col cols="12">
                  <h6 class="options-title">Options</h6>
                </b-col>
                <b-col cols="5">
                  <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
                    <template #prepend>
                      <b-input-group-text>{{ i + 1 }}</b-input-group-text>
                    </template>
                    <b-form-input v-model="option.value" placeholder="Survey option"></b-form-input>

                    <template #append>
                      <b-input-group-text @click="deleteOption(question, i)" class="danger">
                        <i class="far fa-trash-alt"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                </b-col>
                <b-col cols="2 text-center">
                  <img class="translation_flag" src="../assets/img/german.png" />
                </b-col>
                <b-col cols="5">
                  <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
                    <template #prepend>
                      <b-input-group-text>{{ i + 1 }}</b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="option.value_translated"
                      :placeholder="i==0 ? 'Nein' : 'Ja'"
                    ></b-form-input>

                    <template #append>
                      <b-input-group-text @click="deleteOption(question, i)" class="danger">
                        <i class="far fa-trash-alt"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                </b-col>
                <b-col cols="12">
                  <button class="add-btn" @click="addOption(question)">Add option</button>
                </b-col>
              </b-row>
              <!-- .................yes-no response -->
              <b-row v-if="question.type == 'yes-no'">
                <b-col cols="12">
                  <h6 class="options-title">Options</h6>
                </b-col>
                <b-col cols="5">
                  <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
                    <template #prepend>
                      <b-input-group-text>{{ i + 1 }}</b-input-group-text>
                    </template>
                    <b-form-input v-model="option.value" placeholder="Positive"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="2 text-center">
                  <img class="translation_flag" src="../assets/img/german.png" />
                </b-col>
                <b-col cols="5">
                  <b-input-group class="mb-2" v-for="(option, i) in question.options" :key="i">
                    <template #prepend>
                      <b-input-group-text>{{ i + 1 }}</b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="option.value_translated"
                      :placeholder="i==0 ? 'Nein' : 'Ja'"
                    ></b-form-input>

                    <template #append>
                      <b-input-group-text @click="deleteOption(question, i)" class="danger">
                        <i class="far fa-trash-alt"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                </b-col>
                <b-col cols="12">
                  <button class="add-btn" @click="addOption(question)">Add option</button>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="Jump to existing question">
            <p></p>
          </b-tab>
        </b-tabs>
      </div>

      <template #modal-footer="{ cancelConditions }">
        <b-button variant="outline-success" @click="cancelConditions()">Cancel</b-button>
        <b-button variant="success" @click="okConditions()">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";

import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    Header
  },

  data() {
    return {
      data: "view",
      slack_users: [],
      slack_channels: [],
      submitted: false,
      modalShow: false,
      threaded: true,
      // projects: [],
      langsAvailable: [],
      languageTarget: "",
      assinee: [],
      async_delivery: true,
      activeQueIndex: 0,
      days_list: [],
      days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      tzStrings: [
        // {"label":"(GMT-12:00) International Date Line West","value":"Etc/GMT+12", value: "" },
        {
          label: "(GMT-12:00) International Date Line West",
          value: "Etc/GMT+12"
        },
        { label: "(GMT-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
        { label: "(GMT-10:00) Hawaii", value: "Pacific/Honolulu" },
        { label: "(GMT-09:00) Alaska", value: "US/Alaska" },
        {
          label: "(GMT-08:00) Pacific Time (US & Canada)",
          value: "America/Los_Angeles"
        },
        {
          label: "(GMT-08:00) Tijuana, Baja California",
          value: "America/Tijuana"
        },
        { label: "(GMT-07:00) Arizona", value: "US/Arizona" },
        {
          label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
          value: "America/Chihuahua"
        },
        {
          label: "(GMT-07:00) Mountain Time (US & Canada)",
          value: "US/Mountain"
        },
        { label: "(GMT-06:00) Central America", value: "America/Managua" },
        {
          label: "(GMT-06:00) Central Time (US & Canada)",
          value: "US/Central"
        },
        {
          label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
          value: "America/Mexico_City"
        },
        { label: "(GMT-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
        {
          label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco",
          value: "America/Bogota"
        },
        {
          label: "(GMT-05:00) Eastern Time (US & Canada)",
          value: "US/Eastern"
        },
        { label: "(GMT-05:00) Indiana (East)", value: "US/East-Indiana" },
        {
          label: "(GMT-04:00) Atlantic Time (Canada)",
          value: "Canada/Atlantic"
        },
        { label: "(GMT-04:00) Caracas, La Paz", value: "America/Caracas" },
        { label: "(GMT-04:00) Manaus", value: "America/Manaus" },
        { label: "(GMT-04:00) Santiago", value: "America/Santiago" },
        { label: "(GMT-03:30) Newfoundland", value: "Canada/Newfoundland" },
        { label: "(GMT-03:00) Brasilia", value: "America/Sao_Paulo" },
        {
          label: "(GMT-03:00) Buenos Aires, Georgetown",
          value: "America/Argentina/Buenos_Aires"
        },
        { label: "(GMT-03:00) Greenland", value: "America/Godthab" },
        { label: "(GMT-03:00) Montevideo", value: "America/Montevideo" },
        { label: "(GMT-02:00) Mid-Atlantic", value: "America/Noronha" },
        { label: "(GMT-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
        { label: "(GMT-01:00) Azores", value: "Atlantic/Azores" },
        {
          label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik",
          value: "Africa/Casablanca"
        },
        {
          label:
            "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
          value: "Etc/Greenwich"
        },
        {
          label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
          value: "Europe/Amsterdam"
        },
        {
          label:
            "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
          value: "Europe/Belgrade"
        },
        {
          label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
          value: "Europe/Brussels"
        },
        {
          label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
          value: "Europe/Sarajevo"
        },
        { label: "(GMT+01:00) West Central Africa", value: "Africa/Lagos" },
        { label: "(GMT+02:00) Amman", value: "Asia/Amman" },
        {
          label: "(GMT+02:00) Athens, Bucharest, Istanbul",
          value: "Europe/Athens"
        },
        { label: "(GMT+02:00) Beirut", value: "Asia/Beirut" },
        { label: "(GMT+02:00) Cairo", value: "Africa/Cairo" },
        { label: "(GMT+02:00) Harare, Pretoria", value: "Africa/Harare" },
        {
          label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
          value: "Europe/Helsinki"
        },
        { label: "(GMT+02:00) Jerusalem", value: "Asia/Jerusalem" },
        { label: "(GMT+02:00) Minsk", value: "Europe/Minsk" },
        { label: "(GMT+02:00) Windhoek", value: "Africa/Windhoek" },
        { label: "(GMT+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
        {
          label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
          value: "Europe/Moscow"
        },
        { label: "(GMT+03:00) Nairobi", value: "Africa/Nairobi" },
        { label: "(GMT+03:00) Tbilisi", value: "Asia/Tbilisi" },
        { label: "(GMT+03:30) Tehran", value: "Asia/Tehran" },
        { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
        { label: "(GMT+04:00) Baku", value: "Asia/Baku" },
        { label: "(GMT+04:00) Yerevan", value: "Asia/Yerevan" },
        { label: "(GMT+04:30) Kabul", value: "Asia/Kabul" },
        { label: "(GMT+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
        {
          label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
          value: "Asia/Karachi"
        },
        {
          label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
          value: "Asia/Kolkata"
        },
        { label: "(GMT+05:30) Sri Jayawardenapura", value: "Asia/Kolkata" },
        { label: "(GMT+05:45) Kathmandu", value: "Asia/Katmandu" },
        { label: "(GMT+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
        { label: "(GMT+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
        { label: "(GMT+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
        { label: "(GMT+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
        { label: "(GMT+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
        {
          label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
          value: "Asia/Hong_Kong"
        },
        {
          label: "(GMT+08:00) Kuala Lumpur, Singapore",
          value: "Asia/Kuala_Lumpur"
        },
        { label: "(GMT+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
        { label: "(GMT+08:00) Perth", value: "Australia/Perth" },
        { label: "(GMT+08:00) Taipei", value: "Asia/Taipei" },
        { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
        { label: "(GMT+09:00) Seoul", value: "Asia/Seoul" },
        { label: "(GMT+09:00) Yakutsk", value: "Asia/Yakutsk" },
        { label: "(GMT+09:30) Adelaide", value: "Australia/Adelaide" },
        { label: "(GMT+09:30) Darwin", value: "Australia/Darwin" },
        { label: "(GMT+10:00) Brisbane", value: "Australia/Brisbane" },
        {
          label: "(GMT+10:00) Canberra, Melbourne, Sydney",
          value: "Australia/Canberra"
        },
        { label: "(GMT+10:00) Hobart", value: "Australia/Hobart" },
        { label: "(GMT+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
        { label: "(GMT+10:00) Vladivostok", value: "Asia/Vladivostok" },
        {
          label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia",
          value: "Asia/Magadan"
        },
        {
          label: "(GMT+12:00) Auckland, Wellington",
          value: "Pacific/Auckland"
        },
        {
          label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is.",
          value: "Pacific/Fiji"
        },
        { label: "(GMT+13:00) Nuku'alofa", value: "Pacific/Tongatapu" }
      ],
      repeatType: [
        { text: "Non-Recurring", value: "Non-Recurring" },
        { text: "Every Week", value: "Every Week" },
        { text: "Every 2 Week", value: "Every 2 Week" },
        { text: "Every 3 Week", value: "Every 3 Week" },
        { text: "Every 4 Week", value: "Every 4 Week" }
      ],
      meetingLength: [
        { text: "15 Min", value: "15" },
        { text: "30 Min", value: "30" },
        { text: "45 Min", value: "45" },
        { text: "1 Hour", value: "60" },
        { text: "1.5 Hours", value: "90" },
        { text: "2 Hours", value: "120" },
        { text: "3 Hours", value: "180" },
        { text: "4 Hours", value: "240" },
        { text: "6 Hours", value: "320" },
        { text: "8 Hours", value: "480" },
        { text: "12 Hours", value: "720" },
        { text: "16 Hours", value: "960" },
        { text: "24 Hours", value: "1400" }
      ],
      // step: 1,
      welcomeMessage: "",
      reportName: "",
      welcomeMessageTranslated: "",
      questions: [
        {
          type: "",
          question: "",
          jira: {
            projects: [],
            assignee: [],
            activeSprint: false,
            suggestPlanned: false,
            individualTicketComment: false,
            syncComments: false,
            allowToWrite: false
          },
          questionTranslated: "",
          conditionCount: 0,
          conditions: [
            {
              users: [],
              days: [],
              anonymous: true,
              attach_chart: false,
              conditions: [
                {
                  logic_type: "AND",
                  logic: {
                    logic_type: "Answer",
                    operator: "et",
                    value: ""
                  },
                  action: 1,
                  jump_order: null,
                  question: {
                    question: "",
                    question_translated: "",
                    que_type: "text-response",
                    options: [
                      {
                        value: "",
                        value_translated: "",
                        type: false
                      },
                      {
                        value: "",
                        value_translated: "",
                        type: false
                      }
                    ]
                  }
                }
              ]
            }
          ],
          iconType: "",
          showIconInsteadOfText: false,
          options: [
            {
              value: "",
              value_translated: "",
              type: false
            },
            {
              value: "",
              value_translated: "",
              type: false
            }
          ]
        }
      ],

      isActive: false,
      checked: false,

      //  options : [
      //     {  text: "thumbs up + thumbs down icon", imagePositive: '/assets/img/like-removebg-preview.png', imageNagative: 'https://someurl/imagepath/thumbsdown.png', value: 'thumbs-up-down-icon' },
      //     ],

      LANGS: [
        { name: "en", title: "English" },
        { name: "de", title: "German" },
        { name: "fr", title: "French" },
        { name: "es", title: "Spanish" },
        { name: "it", title: "Italian" },
        { name: "nl", title: "Dutch" },
        { name: "pl", title: "Polnish" }
      ],
      yesnoTypes: [
        { value: "", text: "Please select" },
        {
          text: "YES/NO icons",
          value: "yes-no",
          imageNegative: "/assets/img/dislike-removebg-preview.png",
          imagePositive: "/assets/img/like-removebg-preview.png"
        },
        {
          text: "Thumbs up/down icons",
          value: "thumbs-up-down-icon",
          imageNegative: "/assets/img/dislike-removebg-preview.png",
          imagePositive: "/assets/img/like-removebg-preview.png"
        },
        {
          text: "Check/cross mark icons",
          value: "check-cross-mark-icon",
          imageNegative: "/assets/img/dislike-removebg-preview.png",
          imagePositive: "/assets/img/like-removebg-preview.png"
        },
        {
          text: "NO/YES icons (reversed/inverted meaning)",
          value: "no-yes",
          imageNegative: "/assets/img/dislike-removebg-preview.png",
          imagePositive: "/assets/img/like-removebg-preview.png"
        }
      ],
      questionTypes: [
        { text: "Select question type", value: "" },
        { text: "No response needed", value: "no-response-needed" },
        { text: "Text response", value: "text-response" },
        { text: "Single-choice survey question", value: "single-choice" },
        { text: "Multiple-choice survey question", value: "multiple-choice" },
        { text: "Yes/No question", value: "yes-no" },
        { text: "Task response", value: "task-response" }
      ],
      schedule: {
        respondents: [],
        start_date: new Date(),
        send_time: "12:00",
        send_days: [],
        repeat_type: "Every Week",
        timezone: "Etc/GMT+12",
        waiting_time: "30",
        end_time_reminder: true,
        end_time: "15",
        reminder_message:
          "The standup is running. Please answer the questions in time"
      },
      delivery: {
        emails: [],
        users: [],
        channels: []
      }
    };
  },
  computed: {
    ...mapState([
      "activeTeam",
      "userProfile",
      "accessToken",
      "projects",
      "assignee"
    ]),

    step: {
      get() {
        return Number(this.$route.query.step || 1);
      },

      set(step) {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            step
          }
        });
      }
    }
  },

  methods: {
    generateTimezone(timeZone) {
      let date = new Date();
      date.setMinutes(date.getMinutes() + 2);
      let strTime = date.toLocaleTimeString("en-GB", {
        timeZone: `${timeZone}`
      });
      // this.schedule.send_time = strTime
      console.log("strTime", strTime);
      console.log(this.schedule.send_time);
    },
    dateRange(startDate, endDate) {
      const dateArray = [];
      let currentDate = new Date(startDate);

      while (currentDate <= new Date(endDate)) {
        dateArray.push(new Date(currentDate).toISOString().slice(0, 10));
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateArray.sort(
        (a, b) => new Date(a).getDay() - new Date(b).getDay()
      );
    },
    okConditions() {
      this.questions[this.activeQueIndex].conditionCount = 1;
      this.modalShow = false;
    },

    // check(e){
    //   if(this.checked = true){
    //   console.log(e,"");
    //   this.checkOptionType();
    //   }
    checkOptionType(key) {
      if (this.questions[key].type == "yes-no") {
        this.questions[key].options[0].value = "Yes";
        this.questions[key].options[1].value = "No";
      } else {
        this.questions[key].options[0].value = "";
        this.questions[key].options[1].value = "";
      }
      if (this.questions[key].type == "yes-no") {
        this.questions[key].options[0].value_translated = "Ja";
        this.questions[key].options[1].value_translated = "Nein";
      } else {
        this.questions[key].options[0].value_translated = "";
        this.questions[key].options[1].value_translated = "";
      }
    },
    showoptiontype() {
      // if (this.iconType == "yes-no") {
      //   this.questions[key].options[0].value = "Yes";
      //   this.questions[key].options[1].value = "No";
      // } else if (this.iconType == "no-yes") {
      //   this.questions[key].options[0].value = "No";
      //   this.questions[key].options[1].value = "yes";
      // } else if (this.iconType == "thumbs-up-down-icon") {
      //   this.questions[key].options[0].value = "";
      //   // this.questions[key].options[1].value = this.yesnoTypes.imageNegative;
      //   console.log(this.options.imagePositive);
      // } else {
      //   this.questions[key].options[0].value = "";
      // }
      //   this.questions[key].options[1].value = "";
    },
    submitReport() {
      if (this.step == 3) {
        let users = [];
        let channels = [];
        let respondents = [];

        this.delivery.users.forEach(user => {
          users.push(user.id);
        });

        this.delivery.channels.forEach(channel => {
          channels.push(channel.id);
        });

        this.schedule.respondents.forEach(user => {
          respondents.push(user.id);
        });
        let send_days = this.schedule.send_days
          .concat(this.days_list)
          .filter((v, i, a) => a.indexOf(v) != i);

        let data = {
          active: true,
          title: this.reportName,
          team: this.activeTeam.teamId,
          creator: this.userProfile.userId,
          welcome_message: this.welcomeMessage,
          welcome_message_translated: this.welcomeMessageTranslated,
          translation: "DE",
          start_date: this.schedule.start_date,
          timezone: this.schedule.timezone,
          send_time: this.schedule.send_time,
          send_days: send_days,
          recurring_type: this.schedule.repeat_type,
          wait_time: this.schedule.waiting_time,
          reminder_time: this.schedule.end_time,
          end_time_reminder: this.schedule.end_time_reminder,
          reminder_message: this.schedule.reminder_message,
          respondents: respondents,
          delivery_emails: this.delivery.emails,
          delivery_users: users,
          delivery_channels: channels,
          threaded: this.threaded,
          async_delivery: this.async_delivery,
          questions: []
        };

        for (let index = 0; index < this.questions.length; index++) {
          const que = this.questions[index];
          console.log(que);
          data.questions.push({
            order: index,
            question: que.question,
            question_translated: que.questionTranslated,
            que_type: que.type,
            options: que.options,
            showIconInsteadOfText: que.showIconInsteadOfText,
            iconType: que.iconType,
            conditions: que.conditions
          });
        }

        if (this.$route.params.id) {
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${this.accessToken}`
            },
            body: JSON.stringify(data)
          };

          fetch(
            `${process.env.VUE_APP_API_URL}standup/${this.$route.params.id}`,
            requestOptions
          )
            .then(response => response.json())
            .then(() => {
              this.$toast.open({
                message: "Report saved successfully",
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top-right"
              });

              this.$router.push({
                path: "/reports"
              });
            });
        } else {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${this.accessToken}`
            },
            body: JSON.stringify(data)
          };

          fetch(`${process.env.VUE_APP_API_URL}standup`, requestOptions)
            .then(response => response.json())
            .then(() => {
              this.$toast.open({
                message: "Report saved successfully",
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top-right"
              });

              this.$router.push({
                path: "/reports"
              });
            });
        }
      } else {
        this.$toast.open({
          message: "Complete all the steps before you continue.",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "top-right"
        });
      }
    },
    getAssignee() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.accessToken}`
        },
        body: JSON.stringify({
          code: this.activeTeam.teamCode,
          team: this.activeTeam.teamId,
          project: this.projects
        })
      };

      fetch(`${process.env.VUE_APP_API_URL}jira/assignee/`, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.assignee = data;
        });
    },
    toggleCondition(index) {
      this.modalShow = !this.modalShow;
      this.activeQueIndex = index;
    },
    sendTest() {
      if (this.step == 3) {
        let users = [];
        let channels = [];
        let respondents = [];

        this.delivery.users.forEach(user => {
          users.push(user.id);
        });

        this.delivery.channels.forEach(channel => {
          channels.push(channel.id);
        });

        this.schedule.respondents.forEach(user => {
          respondents.push(user.id);
        });

        let data = {
          active: true,
          title: this.reportName,
          team: this.activeTeam.teamId,
          creator: this.userProfile.userId,
          welcome_message: this.welcomeMessage,
          welcome_message_translated: this.welcomeMessageTranslated,
          translation: "DE",
          start_date: this.schedule.start_date,
          timezone: this.scheduletimezone,
          // send_time: this.schedule.send_time,
          send_time: this.generateDatabaseDateTime(new Date()),
          send_days: this.schedule.send_days,
          recurring_type: this.schedule.repeat_type,
          wait_time: this.schedule.waiting_time,
          reminder_time: this.schedule.end_time,
          end_time_reminder: this.schedule.end_time_reminder,
          reminder_message: this.schedule.reminder_message,
          respondents: respondents,
          delivery_emails: this.delivery.emails,
          delivery_users: users,
          delivery_channels: channels,
          threaded: this.threaded,
          async_delivery: this.async_delivery,
          questions: []
        };

        for (let index = 0; index < this.questions.length; index++) {
          const que = this.questions[index];
          console.log(que);
          data.questions.push({
            order: index,
            question: que.question,
            question_translated: que.questionTranslated,
            que_type: que.type,
            options: que.options,
            showIconInsteadOfText: que.showIconInsteadOfText,
            iconType: que.iconType,
            conditions: que.conditions
          });
        }

        if (this.$route.params.id) {
          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${this.accessToken}`
            },
            body: JSON.stringify(data)
          };

          fetch(
            `${process.env.VUE_APP_API_URL}standup/${this.$route.params.id}`,
            requestOptions
          )
            .then(response => response.json())
            .then(() => {
              this.$toast.open({
                message: "Report saved successfully",
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top-right"
              });

              this.$router.push({
                path: "/reports"
              });
            });
        } else {
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${this.accessToken}`,
              body: JSON.stringify(data)
            }
          };

          fetch(`${process.env.VUE_APP_API_URL}standup`, requestOptions)
            .then(response => response.json())
            .then(() => {
              this.$toast.open({
                message: "Report saved successfully",
                type: "success",
                duration: 5000,
                dismissible: true,
                position: "top-right"
              });

              this.$router.push({
                path: "/reports"
              });
            });
        }
      } else {
        this.$toast.open({
          message: "Complete all the steps before you continue.",
          type: "error",
          duration: 5000,
          dismissible: true,
          position: "top-right"
        });
      }
    },
    generateDatabaseDateTime(date) {
      date.setMinutes(date.getMinutes() + 1);
      let time = date
        .toISOString()
        .replace("T", "")
        .substring(0, 19);
      return time.slice(10, 18);
    },
    //   const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       questions: this.questions,
    //       delivery: this.delivery,
    //     }),
    //   };
    //   fetch(
    //     `${process.env.VUE_APP_API_URL}/standup/test-report`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((data) => (this.postId = data.id));
    // },

    addQuestion() {
      this.questions.push({
        type: "",
        iconType: "",
        showIconInsteadOfText: false,
        question: "",
        conditionCount: 0,
        conditions: [],
        options: [
          {
            value: "",
            value_translated: "",
            type: false
          },
          {
            value: "",
            value_translated: "",
            type: false
          }
        ]
      });
    },
    addOption(question) {
      question.options.push({
        value: ""
      });
    },
    deleteOption(question, index) {
      question.options.splice(index, 1);
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
    },
    moveQuestion(dir, index) {
      if (dir == "up") {
        if (index != 0)
          this.array_move(this.questions, -index, -1 * (index + 1));
      } else {
        if (index != this.questions.length - 1)
          this.array_move(this.questions, index, index + 1);
      }
    },
    array_move(arr, old_index, new_index) {
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing purposes
    },
    upValue() {
      this.questions.prototype.move = (from, to) => {
        this.splice(to, 0, this.splice(from, 1)[0]);
      };
    },
    setsteps(e) {
      console.log(e);
    },
    nextStep() {
      if (this.step == 1) {
        if (this.checkQuestions()) {
          this.step = 2;
        }
      } else {
        this.step = 3;
      }
    },
    prevStep() {
      if (this.step == 2) {
        this.step = 1;
      } else {
        this.step = 2;
      }
    },
    getStandup() {
      if (this.$route.params.id) {
        fetch(
          `${process.env.VUE_APP_API_URL}standup/${this.$route.params.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${this.accessToken}`
            }
          }
        )
          .then(response => response.json())
          .then(data => {
            this.welcomeMessage = data.welcome_message;
            this.reportName = data.title;
            this.welcomeMessageTranslated = data.welcome_message_translated;
            this.questions = [];

            data.questions.forEach(q => {
              let conditions = [];
              let options = [];

              q.options.forEach(o => {
                options.push({
                  value: o.value,
                  value_translated: o.value_translated,
                  type: o.type ? o.type : false
                });
              });

              this.questions.push({
                type: q.que_type,
                question: q.question,
                questionTranslated: q.question_translated,
                conditions: conditions,
                conditionCount: 0,
                options: options,
                showIconInsteadOfText: q.showIconInsteadOfText,
                iconType: q.iconType
              });
            });

            let users = [];
            let respondents = [];
            let channels = [];

            this.slack_users.forEach(u => {
              // console.log(data.delivery_users.includes(u.id));
              // console.log(data.delivery_channels.includes(u.id));

              if (data.delivery_users && data.delivery_users.includes(u.id)) {
                users.push(u);
              }
              if (data.respondents && data.respondents.includes(u.id)) {
                respondents.push(u);
              }
            });

            console.log(this.slack_channels);
            console.log(this.slack_users);

            this.slack_channels.forEach(c => {
              console.log(data.delivery_channels.includes(c.id));
              if (
                data.delivery_channels &&
                data.delivery_channels.includes(c.id)
              ) {
                channels.push(c);
              }
            });
            this.schedule = {
              respondents: respondents,
              start_date: new Date(data.start_date),
              send_time: data.send_time,
              timezone: data.timezone,
              send_days: data.send_days,
              repeat_type: data.recurring_type,
              waiting_time: data.wait_time,
              end_time_reminder: data.end_time_reminder,
              end_time: data.reminder_time,
              reminder_message: data.reminder_message
            };

            this.delivery = {
              emails: data.delivery_emails,
              users: users,
              channels: channels
            };
          });
      }
    },
    checkQuestions() {
      for (let index = 0; index < this.questions.length; index++) {
        const element = this.questions[index];

        if (element.question == "" || !element.question) {
          return false;
        }

        if (
          element.type == "single-choice" ||
          element.type == "multiple-choice"
        ) {
          if (element.options.length < 2) {
            return false;
          }

          for (let index = 0; index < element.options.length; index++) {
            const item = element.options[index];

            if (item.value == "" || !item.value) {
              return false;
            }
          }
        }
      }
      return true;
    }
  },
  mounted() {
    var currentDate = new Date();
    var firstday = new Date(
      currentDate.setDate(currentDate.getDate())
    ).toUTCString();
    var lastday = new Date(
      currentDate.setDate(currentDate.getDate() + 6)
    ).toUTCString();
    let list = this.dateRange(firstday, lastday);
    let date = list[0];
    list.splice(0, 1);
    list.push(date);

    this.days_list = list;
    setTimeout(() => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${this.accessToken}`
        },
        body: JSON.stringify({
          code: this.activeTeam.teamCode,
          team: this.activeTeam.teamId
        })
      };
      fetch(`${process.env.VUE_APP_API_URL}user/slack_users`, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.slack_users = [];

          data.users.members.forEach(user => {
            if (!user.is_bot) {
              this.slack_users.push({
                name: user.real_name,
                id: user.id,
                image: user.profile.image_24
              });
            }
          });

          this.slack_channels = data.channels.channels;
          this.getStandup();
        });

      fetch(`${process.env.VUE_APP_API_URL}jira/projects`, requestOptions)
        .then(response => response.json())
        .then(data => {
          this.projects = data;
        });
    }, 500);
  }
};
</script>

<style scoped lang="scss">
.label {
  margin-bottom: 8px;
  margin-top: 25px;
}

.translation_flag {
  width: 50px;
}

.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 25px;
}

.mr-2 {
  margin-right: 15px;
}

.question-box {
  position: relative;
}

.question-box .side-button {
  position: absolute;
  left: -44px;
  padding: 8px 8px;
  top: 0;
  bottom: 0;
  display: none;
}

.question-box:hover .side-button {
  display: block;
}

.conditions-btn span {
  background: "#fff";
  padding-right: 5px;
  padding-left: 5px;
  background: #ffffff;
  color: #222;
}

.conditions-btn span.active {
  background: #72bf83;
}

.cross-btn {
  display: block;
  color: red;
  font-size: 32px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.arrow-btn {
  display: block;
  font-size: 32px;
  cursor: pointer;
  color: gray;
  width: 100%;
  text-align: center;
}

.cross-btn:hover,
.arrow-btn:hover {
  opacity: 0.6;
}

h6 {
  color: #7f8886;
}
.createReport {
  position: relative;
}
.space-50 {
  height: 50px;
  width: 100%;
}
.add-btn {
  width: 100%;
  border: 2px dashed #b0b6ba;
  background: transparent;
  padding: 5px;
  margin-top: 20px;
  color: #167bfc;
  border-radius: 8px;

  &:hover {
    opacity: 0.8;
  }
}
.space-100 {
  height: 100px;
  width: 100%;
}
.report-header {
  padding: 20px 0;
  background: linear-gradient(90deg, #87e7e5 38%, #a2d581 100%);
}
.report-footer {
  padding: 20px 0;
  background: linear-gradient(90deg, #87e7e5 38%, #a2d581 100%);
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 10px;
    width: 82px;
  }
}
.steps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.step {
  border: 1px solid #7f8886;
  border-radius: 36px;
  min-width: 36px;
  padding: 5px;
  text-align: center;
  display: inline-block;

  span {
    display: none;
  }
}

.danger {
  color: red;
  cursor: pointer;
}

.input-group-text {
  font-size: 14px !important;
}

.input-group-prepend {
  .input-group-text {
    border-right: 0;
  }
}

.input-group-append {
  .input-group-text {
    border-left: 0;
  }
}

.input-group .form-control {
  border-left: 0;
  border-right: 0;
}

.step.active {
  background: #fff;
  font-weight: bold;

  span {
    display: inline-block;
  }
}

.divider {
  display: inline-block;
  width: 15px;
  height: 1px;
  background: #7f8886;
}

.mt-2 {
  margin-top: 2rem;
}

.options-title {
  color: #000;
  margin-top: 20px;
}

.input-group-text {
  background: #fff;
}

.team-wrapper {
  p {
    margin-bottom: 0;
  }
}

.question-box {
  background: #f5f5f5;
  padding: 20px 20px;
  border-radius: 5px;
  margin-top: 20px;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.4);
  box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.4);
}

.questions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-title {
  border: 1px solid #757575;
  width: 100%;
  border-radius: 5px;
}
</style>
    